import { Controller } from "@hotwired/stimulus"
import MagicGrid from "magic-grid"

export default class MosaicController extends Controller {
  static values = {
    columns: Number,
    gutter: { type: Number, default: 25 },
  }

  connect() {
    const magicGrid = new MagicGrid({
      container: this.element,
      gutter: this.gutterValue,
      maxColumns: this.columnsValue,
      static: true,
      useMin: true,
    })
    magicGrid.listen()
  }
}
