import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { useDebounce } from "stimulus-use"

export default class FormController extends Controller {
  static values = {
    responseKind: {
      type: String,
      default: "html",
    },
  }
  static debounces = [
    {
      name: "debouncedBackgroundSubmit",
      wait: 1000,
    },
  ]
  static targets = ["clear"]
  static outlets = ["globe"]

  connect() {
    this.formModified = false
    this.element.addEventListener("input", () => {
      this.formModified = true
    })
    this.element.addEventListener("trix-paste", () => {
      this.formModified = true
    })
    useDebounce(this)
  }

  submit() {
    this.element.requestSubmit()
  }

  reset() {
    this.element.reset()
    for (const input of this.element.elements) {
      const resetEvent = new Event("reset", { bubbles: false })
      input.dispatchEvent(resetEvent)
    }
  }

  clear() {
    for (const input of this.clearTargets) {
      switch (input.type) {
        case "checkbox":
        case "radio": {
          input.checked = false
          break
        }
        case "select-one":
        case "select-multiple": {
          input.selectedIndex = -1
          break
        }
        case "submit":
        case "reset":
        case "button":
        case "menu": {
          break
        }
        default: {
          input.value = ""
          break
        }
      }
      input.dispatchEvent(new Event("change"))
    }
  }

  async backgroundSubmit() {
    if (this.formModified) {
      const url = this.element.action
      const formData = new FormData(this.element)
      const response = await post(url, {
        body: formData,
        responseKind: this.responseKindValue,
      })
      if (response.ok) {
        this.formModified = false
      }
    }
  }

  debouncedBackgroundSubmit() {
    this.backgroundSubmit()
  }

  updateOutlets() {
    const url = new URL(this.element.action)
    url.search = new URLSearchParams(new FormData(this.element)).toString()
    for (const controller of this.globeOutlets) {
      controller.reloadData(url.toString())
    }
  }

  resetOutlets() {
    for (const controller of this.globeOutlets) {
      controller.resetZoom()
    }
  }

  focus({ params: { focusInputName } }) {
    let inputs = this.element.elements.namedItem(focusInputName)
    if (!(inputs instanceof NodeList)) {
      inputs = [inputs]
    }
    for (const i of inputs) {
      i.focus()
    }
  }
}
