import { Controller } from "@hotwired/stimulus"
import simpleFormat from "../lib/simple_format"

export default class OutputController extends Controller {
  static values = {
    format: String,
    falsy: { type: String, default: null },
  }

  connect() {
    this.update()
    this.element.form?.addEventListener("reset", () =>
      setTimeout(() => this.update(), 50),
    )
  }

  update() {
    let value = Number.parseInt(this.element.value, 10)
    if (Number.isNaN(value)) {
      value = this.element.value
    }

    const formattedValue =
      !value && this.falsyValue
        ? this.falsyValue
        : simpleFormat(this.formatValue, "?", value)

    const outputElements = document.querySelectorAll(
      `output[for=${this.element.id}]`,
    )
    for (const el of outputElements) {
      el.textContent = formattedValue
    }
  }
}
