import Bugsnag from "@bugsnag/browser"
import BugsnagPerformance from "@bugsnag/browser-performance"

function start(api) {
  api.start({
    apiKey: ENV.BUGSNAG_API_KEY,
    appVersion: ENV.BUGSNAG_APP_VERSION,
    enabledReleaseStages: ENV.BUGSNAG_ENABLED_RELEASE_STAGES,
    releaseStage: ENV.BUGSNAG_RELEASE_STAGE,
  })
}

if (ENV.BUGSNAG_API_KEY) {
  try {
    start(Bugsnag)
  } catch (error) {
    console.error(`Could not start up bugsnag due to: ${error.message}`)
  }

  try {
    start(BugsnagPerformance)
  } catch (error) {
    console.error(
      `Could not start up bugsnag performance due to: ${error.message}`,
    )
  }
}
