import { Controller } from "@hotwired/stimulus"

export default class ClearInputsController extends Controller {
  static targets = ["clear", "notifyClear"]

  clear() {
    for (const input of this.clearTargets) {
      switch (input.type) {
        case "checkbox":
        case "radio": {
          input.checked = false
          input.dispatchEvent(new Event("input"))
          break
        }
        case "select-one":
        case "select-multiple": {
          input.selectedIndex = -1
          input.dispatchEvent(new Event("change"))
          break
        }
        case "submit":
        case "reset":
        case "button":
        case "menu": {
          break
        }
        default: {
          input.value = ""
          input.dispatchEvent(new Event("input"))
          break
        }
      }
    }
    for (const el of this.notifyClearTargets) {
      el.dispatchEvent(new Event("clear", { bubbles: true }))
    }
  }
}
