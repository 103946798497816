import sanitizeHtml from "../lib/sanitize_html"

document.addEventListener("trix-before-paste", function (e) {
  const html = e.paste.html
  if (html) {
    e.paste.html = sanitizeHtml(html, {
      allowedTags: ENV.TRIX_ALLOWED_TAGS,
      allowedAttrs: ENV.TRIX_ALLOWED_ATTRS,
    })
  }
})

document.addEventListener("trix-file-accept", function (e) {
  e.preventDefault()
})
