import Reveal from "@stimulus-components/reveal"

export default class RevealController extends Reveal {
  static targets = ["item", "invertedItem"]

  toggle() {
    super.toggle()
    for (const item of this.invertedItemTargets) {
      item.classList.toggle(this.class)
    }
  }

  show() {
    super.show()
    for (const item of this.invertedItemTargets) {
      item.classList.add(this.class)
    }
  }

  hide() {
    super.hide()
    for (const item of this.invertedItemTargets) {
      item.classList.remove(this.class)
    }
  }
}
