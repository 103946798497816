import DOMPurify from "dompurify"

export default function sanitizeHtml(
  html,
  { allowedTags = [], allowedAttrs = [] } = {},
) {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: allowedAttrs,
    SANITIZE_DOM: false,
  })
}
