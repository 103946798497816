export default function setSearchParams(urlStr, params) {
  let url
  try {
    url = new URL(urlStr)
  } catch {
    return urlStr
  }
  const searchParams = url.searchParams
  for (const name in params) {
    const val = params[name]
    if (Array.isArray(val)) {
      searchParams.delete(name)
      for (const subVal of val) {
        searchParams.append(name, subVal)
      }
    } else {
      searchParams.set(name, val)
    }
  }
  return url.toString()
}
