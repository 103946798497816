import { Controller } from "@hotwired/stimulus"

export default class InputTextController extends Controller {
  static targets = ["item", "input"]
  static values = {
    default: String,
    prefix: String,
    suffix: String,
  }

  connect() {
    this.reset()
  }

  reset() {
    const inputValues = []
    for (const input of this.inputTargets) {
      switch (input.type) {
        case "radio":
        case "checkbox": {
          if (input.checked && input.value !== "on") {
            if (input.labels.length > 0) {
              inputValues.push(input.labels.item(0).textContent)
            } else {
              inputValues.push(input.value)
            }
          }
          break
        }
        case "select-one":
        case "select-multiple": {
          inputValues.push(input.options.item(input.selectedIndex).label)
          break
        }
        default: {
          if (input.value && /\S/.test(input.value)) {
            inputValues.push(input.value)
          }
          break
        }
      }
    }
    const filteredInputValues = inputValues.filter((v) => v && /\S/.test(v))
    const updatedValue =
      filteredInputValues.length > 0
        ? `${this.prefixValue}${filteredInputValues.join(", ")}${this.suffixValue}`
        : this.defaultValue

    for (const target of this.itemTargets) {
      this.replaceText(target, updatedValue)
    }
  }

  private

  replaceText(node, newText) {
    let textReplaced = false
    for (const childNode of node.childNodes) {
      if (
        childNode.nodeType === Node.TEXT_NODE &&
        /\S/.test(childNode.textContent)
      ) {
        if (textReplaced) {
          childNode.remove()
        } else {
          childNode.replaceWith(document.createTextNode(newText))
          textReplaced = true
        }
      }
    }
    if (!textReplaced) {
      node.append(document.createTextNode(newText))
    }
  }
}
