import { Controller } from "@hotwired/stimulus"
import {
  html,
  render,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "htm/preact/standalone"
import PropTypes from "prop-types"
import exact from "prop-types-exact"
import { createAutocomplete } from "@algolia/autocomplete-core"
import debounce from "debounce-promise"
import search from "../lib/search"
import regionFromPoint from "../lib/region_from_point"

function closeOnClickAway({
  getEnvironmentProps,
  isOpen,
  containerRef,
  inputRef,
  panelRef,
}) {
  useEffect(() => {
    if (!containerRef.current || !panelRef.current || !inputRef.current) {
      return
    }

    const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
      formElement: containerRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    })

    window.addEventListener("mousedown", onMouseDown)
    window.addEventListener("touchstart", onTouchStart)
    window.addEventListener("touchmove", onTouchMove)

    return () => {
      window.removeEventListener("mousedown", onMouseDown)
      window.removeEventListener("touchstart", onTouchStart)
      window.removeEventListener("touchmove", onTouchMove)
    }
  }, [getEnvironmentProps, isOpen])
}

const MAPBOX_SOURCE_ID = "mapbox"

function createMapboxGeosearchPlugin({
  accessToken,
  minQueryLength,
  onSelect,
}) {
  return {
    getSources() {
      return [
        {
          sourceId: MAPBOX_SOURCE_ID,
          async getItems({ query }) {
            if (query.length < minQueryLength) {
              return []
            }

            const response = await debounce(() => {
              const resultTypes = ["country", "district", "place", "region"]
              const params = new URLSearchParams({
                access_token: accessToken,
                autocomplete: true,
                types: resultTypes.join(","),
              })
              return fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                  query,
                )}.json?${params.toString()}`,
              )
            }, 300)()

            const { features } = await response.json()
            const featuresWithLabel = features.map((feature) => ({
              ...feature,
              label: feature.place_name,
            }))
            return featuresWithLabel
          },
          getItemInputValue: ({ item: { place_name } }) => place_name,
          onSelect,
        },
      ]
    },
  }
}

function createRestApiPlugin({
  url,
  sourceId,
  queryParam,
  displayAttr,
  minQueryLength,
  onSelect,
}) {
  return {
    getSources() {
      return [
        {
          sourceId,
          async getItems({ query }) {
            if (query.length < minQueryLength) {
              return []
            }

            const response = await debounce(() => {
              const params = new URLSearchParams({
                [queryParam]: query,
              })
              return fetch(`${url}?${params.toString()}`, {
                headers: {
                  Accept: "application/json",
                },
              })
            }, 300)()

            const searchResults = await response.json()
            const resultsWithLabel = searchResults.map((result) => ({
              ...result,
              label: result[displayAttr],
            }))
            return resultsWithLabel
          },
          getItemInputValue: ({ item }) => item[displayAttr],
          onSelect,
        },
      ]
    },
  }
}

function AutocompleteMenu({
  buttonClasses,
  buttonWrapperClasses,
  dataSources,
  enableGeosearch,
  geosearchHeading,
  initialValue,
  inputDataAttributes,
  menuClasses,
  menuHeaderClasses,
  menuIconClasses,
  menuWrapperClasses,
  minQueryLength,
  noMatches,
  noResultsClasses,
  mapboxAccessToken,
  placeholder,
  resultClasses,
  resultLimit,
  searchInputClasses,
  searchInputId,
  searchInputName,
}) {
  const { text: initialQuery, ...initialSelectedItem } = initialValue
  const [autocompleteState, setAutocompleteState] = useState({
    query: initialQuery || "",
  })

  const [selectedItemState, setSelectedItemState] =
    useState(initialSelectedItem)
  const [updatedState, setUpdatedState] = useState(false)

  const containerRef = useRef(null)
  const inputRef = useRef(null)
  const panelRef = useRef(null)

  const dataSourceToSourceId = ({ label, name }) =>
    `${label.toLocaleLowerCase()}/${name}`

  const sourceIdToDataSource = (sourceId) =>
    dataSources.find(
      (dataSource) => dataSourceToSourceId(dataSource) === sourceId,
    )

  const autocomplete = useMemo(() => {
    const plugins = []

    if (enableGeosearch) {
      plugins.push(
        createMapboxGeosearchPlugin({
          accessToken: mapboxAccessToken,
          minQueryLength,
          onSelect: ({ item: { id, bbox, place_name, properties } }) => {
            const coordinates = [
              {
                latitude: bbox[1],
                longitude: bbox[0],
              },
              {
                latitude: bbox[3],
                longitude: bbox[2],
              },
            ]

            const country_code = id.startsWith("country.")
              ? properties.short_code
              : ""

            setSelectedItemState({ coordinates, country_code })
            setUpdatedState(place_name !== initialQuery)
          },
        }),
      )
    }

    for (const dataSource of dataSources) {
      if (dataSource.optionsApi) {
        const {
          optionsApi: { url, param, displayAttr },
        } = dataSource
        plugins.push(
          createRestApiPlugin({
            url: url,
            sourceId: dataSourceToSourceId(dataSource),
            queryParam: param,
            displayAttr,
            minQueryLength,
            onSelect: ({ source: { sourceId }, item }) => {
              const { label, id: value, longitude, latitude } = item

              const name = sourceIdToDataSource(sourceId)?.name
              const newSelectedItemState = { [name]: value }
              if (latitude && longitude) {
                newSelectedItemState.coordinates = regionFromPoint(
                  { latitude, longitude },
                  5000,
                )
              }
              setSelectedItemState(newSelectedItemState)
              setUpdatedState(label !== initialQuery)
            },
          }),
        )
      }
    }

    const getSources = () => {
      return dataSources
        .filter((dataSource) => !!dataSource.options)
        .map((dataSource) => ({
          sourceId: dataSourceToSourceId(dataSource),
          getItems: ({ query }) =>
            search(query, dataSource.options, (option) => option.label).slice(
              0,
              resultLimit,
            ),
          getItemInputValue: ({ item }) => item.label,
          onSelect: ({
            source: { sourceId },
            item: { label, value, latitude, longitude },
          }) => {
            const name = sourceIdToDataSource(sourceId)?.name
            const newSelectedItemState = { [name]: value }
            if (latitude && longitude) {
              newSelectedItemState.coordinates = regionFromPoint(
                { latitude, longitude },
                5000,
              )
            }
            setSelectedItemState(newSelectedItemState)
            setUpdatedState(label !== initialQuery)
          },
        }))
    }

    return createAutocomplete({
      id: searchInputId,
      initialState: autocompleteState,
      onStateChange: ({ state }) => setAutocompleteState(state),
      openOnFocus: true,
      placeholder,
      shouldPanelOpen: ({ state }) => {
        return (
          document.activeElement === inputRef.current &&
          state.query.length >= minQueryLength
        )
      },
      plugins,
      getSources,
      reshape({
        sourcesBySourceId: { [MAPBOX_SOURCE_ID]: mapbox, ...otherSources },
      }) {
        const reorderedSources = []
        if (mapbox) {
          reorderedSources.push(mapbox)
        }
        for (const dataSource of dataSources) {
          reorderedSources.push(otherSources[dataSourceToSourceId(dataSource)])
        }
        return reorderedSources
      },
    })
  }, [
    dataSources,
    enableGeosearch,
    initialQuery,
    mapboxAccessToken,
    minQueryLength,
    resultLimit,
    searchInputId,
  ])

  useEffect(() => {
    if (updatedState && inputRef.current) {
      inputRef.current.dispatchEvent(
        new Event("search", { bubbles: true, cancelable: true }),
      )
      setUpdatedState(false)
    }
  }, [updatedState])

  const { collections, isOpen } = autocompleteState
  const {
    coordinates = [],
    country_code = "",
    ...selectedItem
  } = selectedItemState

  const {
    getEnvironmentProps,
    getInputProps,
    getItemProps,
    getListProps,
    getPanelProps,
    getRootProps,
    refresh,
    setIsOpen,
    setQuery,
  } = autocomplete

  const inputNames = [...new Set(dataSources.map(({ name }) => name))]

  closeOnClickAway({
    getEnvironmentProps,
    isOpen,
    containerRef,
    panelRef,
    inputRef,
  })

  const sectionHeading = (sourceId) =>
    sourceId === MAPBOX_SOURCE_ID
      ? geosearchHeading
      : sourceIdToDataSource(sourceId)?.label

  const clearInternalState = () => {
    setSelectedItemState({})
    setIsOpen(false)
    refresh()
    setUpdatedState(true)
  }

  useEffect(() => {
    const onClear = () => {
      setQuery("")
      clearInternalState()
    }

    if (inputRef.current) {
      inputRef.current.addEventListener("clear", onClear)
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("clear", onClear)
      }
    }
  })

  return html`
    <div class="relative" ref=${containerRef} ...${getRootProps({})}>
      <input
        class="${searchInputClasses}"
        name="${searchInputName}[text]"
        ref=${inputRef}
        ...${getInputProps({
          ...inputDataAttributes,
          onInput: getInputProps({}).onChange,
          onKeyDown: (e, ...rest) => {
            if (e.key === "Enter") {
              clearInternalState()
            }
            getInputProps({}).onKeyDown(e, ...rest)
          },
        })}
      />
      ${inputNames.map(
        (inputName) => html`
          <input
            type="hidden"
            name="${searchInputName}[${inputName}]"
            value="${selectedItem[inputName] || ""}"
          />
        `,
      )}
      ${enableGeosearch &&
      html`
        <input
          type="hidden"
          name="${searchInputName}[country_code]"
          value="${country_code}"
        />
        <input
          type="hidden"
          name="${searchInputName}[coordinates][][longitude]"
          value="${coordinates?.[0]?.longitude || ""}"
        />
        <input
          type="hidden"
          name="${searchInputName}[coordinates][][latitude]"
          value="${coordinates?.[0]?.latitude || ""}"
        />
        <input
          type="hidden"
          name="${searchInputName}[coordinates][][longitude]"
          value="${coordinates?.[1]?.longitude || ""}"
        />
        <input
          type="hidden"
          name="${searchInputName}[coordinates][][latitude]"
          value="${coordinates?.[1]?.latitude || ""}"
        />
      `}
      ${isOpen &&
      html`
        <div
          class="${menuWrapperClasses}"
          ref=${panelRef}
          ...${getPanelProps({})}
        >
          <div class="${menuClasses}">
            ${collections
              .filter(({ items }) => items.length > 0)
              .map(
                ({ source, items }) => html`
                  <div
                    class="flex flex-col animate-slide-in-top-fast"
                    key=${`source-${source.sourceId}`}
                    ...${getListProps()}
                  >
                    <div class="${menuHeaderClasses}">
                      ${sectionHeading(source.sourceId)}
                    </div>
                    ${items.map(
                      (item) => html`
                        <button
                          key="${item.label}"
                          type="button"
                          class="${resultClasses}"
                          ...${getItemProps({
                            item,
                            source,
                          })}
                        >
                          ${item.label}
                        </button>
                      `,
                    )}
                  </div>
                `,
              )}
            ${collections.every(({ items }) => items.length === 0) &&
            html`
              ${collections.length === 1 &&
              html`
                <div class="${menuHeaderClasses}">
                  ${sectionHeading(collections[0].source.sourceId)}
                </div>
              `}
              <div class="${noResultsClasses}">${noMatches}</div>
            `}
            <div class="${buttonWrapperClasses}">
              <button
                class="${buttonClasses}"
                onClick=${setIsOpen.bind(this, false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      `}
      <div class="${menuIconClasses}"></div>
    </div>
  `
}

AutocompleteMenu.propTypes = exact({
  enableGeosearch: PropTypes.bool,
  geosearchHeading: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    PropTypes.exact({
      text: PropTypes.string.isRequired,
      country_code: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(
        PropTypes.exact({
          latitude: PropTypes.string.isRequired,
          longitude: PropTypes.string.isRequired,
        }),
      ),
    }),
  ]),
  dataSources: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.exact({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.exact({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            latitude: PropTypes.number,
            longitude: PropTypes.number,
          }),
        ),
      }),
      PropTypes.exact({
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        optionsApi: PropTypes.exact({
          url: PropTypes.string.isRequired,
          param: PropTypes.string.isRequired,
          displayAttr: PropTypes.string.isRequired,
        }),
      }),
    ]),
  ).isRequired,
  buttonClasses: PropTypes.string,
  buttonWrapperClasses: PropTypes.string,
  inputDataAttributes: PropTypes.object,
  menuClasses: PropTypes.string,
  menuHeaderClasses: PropTypes.string,
  menuIconClasses: PropTypes.string,
  menuWrapperClasses: PropTypes.string,
  minQueryLength: PropTypes.number,
  noMatches: PropTypes.string,
  noResultsClasses: PropTypes.string,
  mapboxAccessToken: PropTypes.string,
  placeholder: PropTypes.string,
  resultClasses: PropTypes.string,
  resultLimit: PropTypes.number,
  searchInputClasses: PropTypes.string,
  searchInputId: PropTypes.string,
  searchInputName: PropTypes.string,
})

export default class AutocompleteController extends Controller {
  static values = {
    dataSources: { type: Array, default: [] },
    enableGeosearch: Boolean,
    geosearchHeading: String,
    inputDataAttributes: { type: Object, default: {} },
    mapboxGeosearchToken: String,
    minQueryLength: { type: Number, default: 0 },
    noMatches: String,
    placeholder: String,
    resultLimit: { type: Number, default: Number.POSITIVE_INFINITY },
    searchInputId: String,
    searchInputName: String,
    selected: { type: Object, default: { text: "" } },
  }

  static classes = [
    "button",
    "buttonWrapper",
    "menu",
    "menuHeader",
    "menuIcon",
    "menuWrapper",
    "noResults",
    "result",
    "searchInput",
  ]

  connect() {
    const props = {
      buttonClasses: this.buttonClasses.join(" "),
      buttonWrapperClasses: this.buttonWrapperClasses.join(" "),
      dataSources: this.dataSourcesValue,
      enableGeosearch: this.enableGeosearchValue,
      geosearchHeading: this.geosearchHeadingValue,
      initialValue: this.selectedValue,
      inputDataAttributes: this.inputDataAttributesValue,
      menuClasses: this.menuClasses.join(" "),
      menuHeaderClasses: this.menuHeaderClasses.join(" "),
      menuIconClasses: this.menuIconClasses.join(" "),
      menuWrapperClasses: this.menuWrapperClasses.join(" "),
      minQueryLength: this.minQueryLengthValue,
      noMatches: this.noMatchesValue,
      noResultsClasses: this.noResultsClasses.join(" "),
      mapboxAccessToken: this.mapboxGeosearchTokenValue,
      placeholder: this.placeholderValue,
      resultClasses: this.resultClasses.join(" "),
      resultLimit: this.resultLimitValue,
      searchInputClasses: this.searchInputClasses.join(" "),
      searchInputId: this.searchInputIdValue,
      searchInputName: this.searchInputNameValue,
    }

    PropTypes.checkPropTypes(
      AutocompleteMenu.propTypes,
      props,
      "prop",
      "AutocompleteMenu",
    )

    render(html` <${AutocompleteMenu} ...${props} /> `, this.element)
  }
}
