import { Controller } from "@hotwired/stimulus"

export default class DomController extends Controller {
  removeElement({ params: { removeSelector } }) {
    const elements = document.querySelectorAll(removeSelector)
    for (const el of elements) {
      el.remove()
    }
  }
}
