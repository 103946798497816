import { Controller } from "@hotwired/stimulus"

export default class ProdpadController extends Controller {
  static targets = ["widget"]

  setEmail({ params: { email } }) {
    if (!email) return

    const setEmailEvent = new CustomEvent("setEmailValue", {
      detail: email,
    })
    this.widgetTarget.dispatchEvent(setEmailEvent)
  }

  setName({ params: { name } }) {
    if (!name) return

    const setNameEvent = new CustomEvent("setNameValue", {
      detail: name,
    })
    this.widgetTarget.dispatchEvent(setNameEvent)
  }

  openDialog() {
    const openDialogEvent = new CustomEvent("openDialog")
    this.widgetTarget.dispatchEvent(openDialogEvent)
  }
}
