import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class SidebarController extends Controller {
  static targets = ["scrollableContainer"]

  static values = {
    name: String,
  }

  connect() {
    useTransition(this, {
      element: this.element,
    })
  }

  toggle(event) {
    if (this.forCurrentSidebar(event)) {
      this.toggleTransition()
      if (event.detail?.scrollToElement) {
        this.scrollToElement(event.detail?.scrollToElement)
      } else {
        if (this.hasScrollableContainerTarget) {
          this.scrollableContainerTarget.scrollTo(0, 0)
        }
      }
    } else {
      this.leave()
    }
  }

  hide(event) {
    if (this.forCurrentSidebar(event)) {
      this.leave()
      if (this.hasScrollableContainerTarget) {
        this.scrollableContainerTarget.scrollTo(0, 0)
      }
    }
  }

  private

  forCurrentSidebar({ detail: { sidebarName } }) {
    return !sidebarName || this.nameValue === sidebarName
  }

  scrollToElement(elementId) {
    if (!elementId) return
    const element = document.querySelector(`#${elementId}`)
    if (!element) return
    element.scrollIntoView({ behavior: "smooth", block: "center" })
    element.focus()
  }
}
