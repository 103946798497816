export default function search(term, items, itemMapping) {
  let matchingItems = []

  const formattedTerm = term
    .toString()
    .toLowerCase()
    .replaceAll(/\s+/g, " ")
    .trim()
  if (formattedTerm.length === 0) {
    matchingItems = items
  } else {
    for (const item of items) {
      const content = itemMapping(item)
      if (content === undefined) continue

      const formattedContent = content.toString().toLowerCase()
      if (formattedContent.includes(formattedTerm)) {
        matchingItems.push(item)
      }
    }
  }

  return matchingItems
}
