import { Controller } from "@hotwired/stimulus"

export default class DuplicateInputsController extends Controller {
  static targets = ["destination", "source"]

  connect() {
    this.update()
  }

  update() {
    const combinedValue = this.textFromInputs(this.sourceTargets).join(", ")
    for (const input of this.destinationTargets) input.value = combinedValue
  }

  textFromInputs(inputs) {
    const textValues = []
    for (const input of inputs) {
      switch (input.tagName) {
        case "SELECT": {
          for (const opt of input.selectedOptions) textValues.push(opt.text)

          break
        }
        case "INPUT": {
          switch (input.type) {
            case "checkbox":
            case "radio": {
              if (input.checked) {
                textValues.push(input.labels[0].textContent)
              }
              break
            }
            default: {
              textValues.push(input.value)
              break
            }
          }
        }
      }
    }
    return textValues.map((v) => v.trim()).filter(Boolean)
  }
}
