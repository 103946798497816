import { Controller } from "@hotwired/stimulus"

export default class AnimationController extends Controller {
  static values = {
    cycle: { type: Number, default: 5 },
  }
  static targets = ["animatedElement"]

  connect() {
    this.interval = setInterval(() => {
      this.reapply()
    }, this.cycleValue * 1000)
    this.reapply()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  reapply() {
    for (const el of this.animatedElementTargets) {
      const klass = el.dataset.animationApplyClass
      el.classList.remove(klass)
      setTimeout(() => {
        el.classList.add(klass)
      }, 10)
    }
  }
}
