import degreesOfLatitude from "./degrees_of_latitude"
import degreesOfLongitude from "./degrees_of_longitude"

export default function regionFromPoint({ latitude, longitude }, regionWidth) {
  const lat = Number.parseFloat(latitude)
  const lng = Number.parseFloat(longitude)
  const latBuffer = degreesOfLatitude({ meters: regionWidth / 2 })
  const lngBuffer = degreesOfLongitude({
    meters: regionWidth / 2,
    latitude,
  })
  return [
    {
      latitude: lat - latBuffer,
      longitude: lng - lngBuffer,
    },
    {
      latitude: lat + latBuffer,
      longitude: lng + lngBuffer,
    },
  ]
}
