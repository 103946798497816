import { Controller } from "@hotwired/stimulus"

export default class SidebarLauncherController extends Controller {
  toggle({ params: { name: sidebarName, scrollTo: scrollToElement } }) {
    this.dispatch("toggle", { detail: { sidebarName, scrollToElement } })
  }

  hide({ params: { name: sidebarName } }) {
    this.dispatch("hide", { detail: { sidebarName } })
  }
}
