import { Controller } from "@hotwired/stimulus"
import search from "../lib/search"

export default class FilterController extends Controller {
  static targets = ["input", "item", "noMatches"]

  static classes = ["hidden"]

  connect() {
    if (this.hasNoMatchesTarget) {
      this.noMatchesTarget.classList.add(this.hiddenCssClass())
    }
    this.filterItems(this.inputTarget.value)
  }

  update({ currentTarget: { value } }) {
    this.filterItems(value)
  }

  filterItems(value) {
    const matchingItems = search(
      value,
      this.itemTargets,
      (item) => item.dataset.filterSearchTermValue,
    )

    for (const item of this.itemTargets) {
      item.classList.toggle(
        this.hiddenCssClass(),
        !matchingItems.includes(item),
      )
    }
    if (this.hasNoMatchesTarget) {
      this.noMatchesTarget.classList.toggle(
        this.hiddenCssClass(),
        matchingItems.length > 0,
      )
    }
  }

  hiddenCssClass() {
    return this.hasHiddenClass ? this.hiddenClass : "hidden"
  }
}
