import { Controller } from "@hotwired/stimulus"

export default class TimeoutController extends Controller {
  static values = {
    eventName: { type: String, default: "timeout" },
    targetSelector: { type: String, default: null },
  }

  start({ params: { duration = 0 } }) {
    setTimeout(() => {
      const timeoutEvent = new CustomEvent(this.eventNameValue)
      const targets = this.targetSelectorValue
        ? document.querySelectorAll(this.targetSelectorValue)
        : [this.element]
      for (const el of targets) {
        el.dispatchEvent(timeoutEvent)
      }
    }, duration)
  }
}
