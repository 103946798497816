import { Controller } from "@hotwired/stimulus"

export default class SharingController extends Controller {
  static targets = [
    "image",
    "mobileSharingLink",
    "copySharingLink",
    "copyCompletedLink",
  ]
  static values = {
    maxImageHeight: { type: Number, default: 600 },
    description: String,
    showSingleButton: { type: Boolean, default: false },
    title: String,
    url: String,
    urlText: String,
  }
  static classes = ["hidden"]

  async connect() {
    await this.waitForImageToLoad(this.imageTarget)

    let showMobileSharingLink = false
    if (this.hasMobileSharingLinkTarget) {
      showMobileSharingLink = await this.isMobileSharingSupported()
      this.toggleLink(this.mobileSharingLinkTarget, showMobileSharingLink)
    }
    if (this.hasCopySharingLinkTarget) {
      const showCopyLink =
        !(this.showSingleButtonValue && showMobileSharingLink) &&
        this.isCopySharingSupported()
      this.toggleLink(this.copySharingLinkTarget, showCopyLink)
      this.toggleLink(this.copyCompletedLinkTarget, false)
    }
  }

  async mobileSharingData() {
    const data = {
      text: `${this.titleValue}\n${this.descriptionValue}`,
      url: this.urlValue,
    }

    try {
      const imageBlob = await this.imageToBlob(this.imageTarget)
      const imageFilename = this.imageTarget.src.split("/").at(-1)
      data["files"] = [
        new File([imageBlob], imageFilename, {
          type: imageBlob.type,
        }),
      ]
    } catch (e) {
      console.warn(e)
    }

    return data
  }

  htmlSharingData() {
    const imageData = this.imageToCanvas(this.imageTarget).toDataURL()
    const html = `
      <a href="${this.urlValue}"><img src="${imageData}"></a>
      <h2>${this.titleValue}</h2>
      <p>${this.descriptionValue}</p>
      <a href="${this.urlValue}">${this.urlTextValue}</a>
    `
    return html
  }

  textSharingData() {
    return this.urlValue
  }

  async mobileShare() {
    const data = await this.mobileSharingData()

    if (!navigator.canShare(data)) {
      throw new Error(`Cannot share data: ${JSON.stringify(data)}`)
    }

    try {
      await navigator.share(data)
    } catch (error) {
      if (error.name !== "AbortError") {
        throw error
      }
    }
  }

  async copy() {
    if (this.isRichCopyingSupported()) {
      await this.copyHtml()
    } else {
      await this.copyText()
    }
    this.toggleLink(this.copySharingLinkTarget, false)
    this.toggleLink(this.copyCompletedLinkTarget, true)
    setTimeout(() => {
      this.toggleLink(this.copySharingLinkTarget, true)
      this.toggleLink(this.copyCompletedLinkTarget, false)
    }, 2000)
  }

  copyHtml() {
    const htmlBlob = new Blob([this.htmlSharingData()], { type: "text/html" })
    const textBlob = new Blob([this.textSharingData()], { type: "text/plain" })

    const item = new window.ClipboardItem({
      [htmlBlob.type]: htmlBlob,
      [textBlob.type]: textBlob,
    })

    return navigator.clipboard.write([item])
  }

  copyText() {
    return navigator.clipboard.writeText(this.textSharingData())
  }

  async isMobileSharingSupported() {
    return !!(
      navigator.canShare && navigator.canShare(await this.mobileSharingData())
    )
  }

  isCopySharingSupported() {
    return !!navigator.clipboard
  }

  isRichCopyingSupported() {
    return window.ClipboardItem !== undefined
  }

  toggleLink(linkTarget, show) {
    linkTarget.classList.toggle(
      this.hasHiddenClass ? this.hiddenClass : "hidden",
      !show,
    )
  }

  async imageToBlob(imageTarget) {
    return await new Promise((resolve, reject) => {
      const canvas = this.imageToCanvas(imageTarget)
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob)
        } else {
          reject(`Could not generate image for ${imageTarget.src}`)
        }
      }, "image/png")
    })
  }

  imageToCanvas(imageTarget) {
    const canvas = document.createElement("canvas")

    const { naturalWidth: imageWidth, naturalHeight: imageHeight } = imageTarget
    const height = Math.min(imageHeight, this.maxImageHeightValue)
    const width = (imageWidth * height) / imageHeight || 0

    canvas.width = width
    canvas.height = height

    const context = canvas.getContext("2d")
    context.drawImage(imageTarget, 0, 0, width, height)

    return canvas
  }

  async waitForImageToLoad(imageTarget) {
    if (!imageTarget.complete) {
      await new Promise((resolve) => {
        imageTarget.addEventListener(
          "load",
          () => {
            resolve()
          },
          { once: true },
        )
      })
    }
  }
}
