import LinkHeader from "http-link-header"

export default function nextLink(linkValue) {
  let link
  try {
    link = LinkHeader.parse(linkValue)
  } catch {
    return
  }
  const nextLink = link.rel("next")
  if (nextLink.length > 0) return nextLink[0].uri
}
