import { Controller } from "@hotwired/stimulus"

export default class FilePreviewController extends Controller {
  static targets = ["image", "filename", "input"]

  refresh() {
    const [previewFile, ..._otherFiles] = this.inputTarget.files

    if (previewFile) {
      const reader = new FileReader()
      reader.addEventListener("load", () => {
        this.imageTarget.src = reader.result
      })

      reader.readAsDataURL(previewFile)
    }
  }

  refreshFilename() {
    const [previewFile, ..._otherFiles] = this.inputTarget.files

    if (previewFile) {
      this.filenameTarget.textContent = previewFile.name
    }
  }
}
