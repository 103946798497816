import { Controller } from "@hotwired/stimulus"

export default class ToggleFieldsetController extends Controller {
  static targets = ["container"]

  enable({ currentTarget }) {
    const activeContainer = this.containerTargets.find((container) =>
      container.contains(currentTarget),
    )

    for (const container of this.containerTargets) {
      const containerFieldsets = container.querySelectorAll("fieldset")
      for (const fieldset of containerFieldsets) {
        fieldset.disabled = activeContainer && container !== activeContainer
      }
    }
  }
}
