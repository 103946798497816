import { Controller } from "@hotwired/stimulus"
import { Sortable } from "sortablejs/modular/sortable.core.esm.js"

export default class PageReorganisationController extends Controller {
  static values = {
    parentId: String,
    scrollEdgeSize: { type: Number, default: 30 },
  }

  connect() {
    const lists = [this.element]
    for (const nestedList of this.nestedLists(this.element)) {
      lists.push(nestedList)
    }
    this.sortables = lists.map((list) => {
      return new Sortable(list, {
        animation: 150,
        draggable: "[data-page-reorganisation-page]",
        fallbackOnBody: true,
        forceAutoScrollFallback: true,
        group: "page-reorganisation",
        onEnd: (e) => this.updatePositions(e),
        scroll: true,
        scrollSensitivity: this.scrollEdgeSizeValue,
        swapThreshold: 0.65,
      })
    })
  }

  disconnect() {
    for (const sortable of this.sortables) {
      sortable.destroy()
    }
  }

  nestedLists(list) {
    return list.querySelectorAll("[data-page-reorganisation-parent-id-value]")
  }

  parentIdInput(item) {
    return item.querySelector("input[data-page-reorganisation-parent-id]")
  }

  pageIdInput(item) {
    return item.querySelector("input[data-page-reorganisation-page-id]")
  }

  positionInput(item) {
    return item.querySelector("input[data-page-reorganisation-position]")
  }

  positionValue(input) {
    return Number.parseInt(input?.value)
  }

  allPages(list, { except = [] }) {
    return [...list.querySelectorAll("[data-page-reorganisation-page]")].filter(
      (p) =>
        p.closest("[data-page-reorganisation-parent-id-value]") === list &&
        !except.includes(p),
    )
  }

  listParentId(list) {
    return list.dataset.pageReorganisationParentIdValue
  }

  lowestPositionInList(list, { except = [] }) {
    const positions = this.allPages(list, { except }).map((page) =>
      this.positionValue(this.positionInput(page)),
    )
    return positions.length === 0 ? 1 : Math.min(...positions)
  }

  updatedPagePosition(currentPosition, { oldPosition, newPosition }) {
    const newPositionIsLowerThanOld = newPosition <= oldPosition
    const lowestPositionToUpdate = newPositionIsLowerThanOld
      ? newPosition
      : oldPosition
    const highestPositionToUpdate = newPositionIsLowerThanOld
      ? oldPosition
      : newPosition

    if (
      lowestPositionToUpdate <= currentPosition &&
      currentPosition <= highestPositionToUpdate
    ) {
      return currentPosition + (newPositionIsLowerThanOld ? 1 : -1)
    } else {
      return currentPosition
    }
  }

  // update positions to match the newly updated indices of the items
  updatePositions({ from, to, newDraggableIndex, item }) {
    const fromParentId = this.listParentId(from)
    const toParentId = this.listParentId(to)
    const itemHasChangedLists = fromParentId !== toParentId

    const oldPosition = this.positionValue(this.positionInput(item))
    const newPosition =
      this.lowestPositionInList(to, {
        except: itemHasChangedLists ? [item] : [],
      }) + newDraggableIndex
    const itemHasChangedPosition = newPosition !== oldPosition

    if (!itemHasChangedPosition && !itemHasChangedLists) return

    const itemPositionInput = this.positionInput(item)
    const itemParentIdInput = this.parentIdInput(item)

    itemPositionInput.value = newPosition
    itemParentIdInput.value = toParentId

    for (const siblingItem of this.allPages(to, { except: [item] })) {
      const siblingPositionInput = this.positionInput(siblingItem)
      const siblingPosition = this.positionValue(siblingPositionInput)

      if (itemHasChangedLists) {
        siblingPositionInput.value =
          siblingPosition + (newPosition <= siblingPosition ? 1 : 0)
      } else {
        siblingPositionInput.value = this.updatedPagePosition(siblingPosition, {
          oldPosition,
          newPosition,
        })
      }
    }
  }
}
