import { Controller } from "@hotwired/stimulus"
import { useMatchMedia } from "stimulus-use"

export default class NewTabController extends Controller {
  static values = {
    mediaQuery: String,
  }

  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        small: this.mediaQueryValue,
      },
    })
  }

  isSmall() {
    this.element.setAttribute("target", "_blank")
  }

  notSmall() {
    this.element.removeAttribute("target")
  }
}
